import React,{useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import {usuarioAutenticado} from '../../actiones/actionsUsuarios';

const RutaPrivada = ({ component: Component, ...props }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(usuarioAutenticado());
        // eslint-disable-next-line
    }, [])

    const autenticado = useSelector(state => state.usuario.autenticado);
    const loading = useSelector(state => state.usuario.loading);

    return ( 
        <Route { ...props } render = { props => !autenticado && !loading ? (
            <Redirect to="/login" />
        ) 
        :
        (
            <Component {...props} />
        )} />
     );
}
 
export default RutaPrivada;