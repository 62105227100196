import React,{useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import {usuarioAutenticado} from '../../actiones/actionsUsuarios';

const SesionIniciada = ({ component: Component, ...props }) => {

    const dispatch = useDispatch();
    const autenticado = useSelector(state => state.usuario.autenticado);

    useEffect(() => {
        dispatch(usuarioAutenticado());
        console.log(autenticado);
        // eslint-disable-next-line
    }, [])

    return ( 
        <Route { ...props } render = { props => autenticado ? (
            <Redirect to="/user/listadoProductos" />
        ) 
        :
        (
            <Component {...props} />
        )} />
     );
}
 
export default SesionIniciada;