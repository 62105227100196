import React from 'react';
import styled from '@emotion/styled';
import ReactHtmlParser from 'react-html-parser';

const ProductoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 0 0 calc(33% - 1rem);
    background-color: #f2f2f2;
    margin:0rem 1rem 2rem 0;
    padding: 2rem;

    p{
        margin:.5rem 0;
    }

`;

const Titulo = styled.p`
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
`;



const Producto = ({img,titulo,detalles}) => {
    return ( 
        <ProductoContainer>
            <img src={img} alt="imagenProducto.jpg" />
            <div>
                <Titulo>{titulo}</Titulo>
                <div>
                { ReactHtmlParser(detalles) }
                </div>
            </div>
        </ProductoContainer>
     );
}
 
export default Producto;