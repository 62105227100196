import {
    CREAR_CATEGORIA,
    CREAR_CATEGORIA_EXITO,
    CREAR_CATEGORIA_ERROR,
    OBTENER_CATEGORIAS,
    OBTENER_CATEGORIAS_EXITO,
    OBTENER_CATEGORIAS_ERROR,
    EDITAR_CATEGORIA,
    EDITAR_CATEGORIA_EXITO,
    EDITAR_CATEGORIA_ERROR,
    ELIMINAR_CATEGORIA,
    ELIMINAR_CATEGORIA_EXITO,
    ELIMINAR_CATEGORIA_ERROR
}from '../types';

import clienteAxios from '../config/clienteAxios';

export function obtenerCategorias () {
    return async (dispatch) => {
        dispatch(obtenerCategoriasPreaparar())

        try {
            const respuesta = await clienteAxios.get('/api/categorias');
            dispatch(obtenerCategoriasExito(respuesta.data.categorias));
        } catch (error) {
            dispatch(obtenerCategoriasError())
        }
    }
}

const obtenerCategoriasPreaparar = () => ({
    type:OBTENER_CATEGORIAS,
})

const obtenerCategoriasExito = (categorias) => ({
    type:OBTENER_CATEGORIAS_EXITO,
    payload:categorias
})

const obtenerCategoriasError = () => ({
    type:OBTENER_CATEGORIAS_ERROR
})

export function crearCategoria (categoria) {
    return async (dispatch) => {

        dispatch(prepararCrearCategoria())

        try {
            const respuesta = await clienteAxios.post('/api/categorias',categoria);

            dispatch(agregarCagoriaExito(respuesta.data.categoria));

        } catch (error) {
            dispatch(agregarCagoriaError());
        }
    }
}


const prepararCrearCategoria = () => ({
    type:CREAR_CATEGORIA
})

const agregarCagoriaExito = (categoria) => ({
    type: CREAR_CATEGORIA_EXITO,
    payload:categoria
})

const agregarCagoriaError = () => ({
    type:CREAR_CATEGORIA_ERROR
})

export function editarCategoria (categoria) {
    return async (dispatch) => {

        dispatch(prepararEditarCategoria())

        try {
            const respuesta = await clienteAxios.put(`/api/categorias/${categoria._id}`,categoria);

            dispatch(editarCagoriaExito(respuesta.data));

        } catch (error) {
            dispatch(editarCagoriaError());
        }
    }
}


const prepararEditarCategoria = () => ({
    type:EDITAR_CATEGORIA
})

const editarCagoriaExito = (categoria) => ({
    type: EDITAR_CATEGORIA_EXITO,
    payload:categoria
})

const editarCagoriaError = () => ({
    type:EDITAR_CATEGORIA_ERROR
})

export function eliminarCategoria (categoria) {
    return async (dispatch) => {
        dispatch(prepararEliminarCategoria());
        try {
            await clienteAxios.delete(`/api/categorias/${categoria._id}`);
            dispatch(eliminarCagoriaExito(categoria))
        } catch (error) {
            dispatch(eliminarCagoriaError());
        }
    }
}

const prepararEliminarCategoria = () => ({
    type:ELIMINAR_CATEGORIA
})

const eliminarCagoriaExito = (categoria) => ({
    type: ELIMINAR_CATEGORIA_EXITO,
    payload:categoria
})

const eliminarCagoriaError = () => ({
    type:ELIMINAR_CATEGORIA_ERROR
})