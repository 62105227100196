import React,{useState,Fragment,useEffect} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Header from './Header';
import styled from '@emotion/styled';
import {useDispatch,useSelector} from 'react-redux';
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import clienteAxios from '../config/clienteAxios';
import {editarPrducto} from '../actiones/actionsProductos';
import {obtenerCategorias} from '../actiones/actionsCategorias';
import {usuarioAutenticado} from '../actiones/actionsUsuarios';


const FormularioNuevoProducto = styled.form`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  select,input{
    margin:2rem 0;
    padding: .5rem;
  }

`;

const Error = styled.p`
  background-color: #d60202;
  color: #fff;
  width: 100%;
  padding: 1rem;
  text-align: center;
`;

const EditarProducto = () => {

  const history = useHistory();

  const dispatch = useDispatch();
  const [producto, setProducto] = useState({});
  const [imagen,guardarImagen] = useState('');
  let { id } = useParams();
  const categorias = useSelector(state => state.categorias.categorias);

  useEffect(() => {
    const consultar = async () => {
      const respuesta = await clienteAxios.get(`/api/productos/producto/${id}`);
      console.log(respuesta.data.productos);
      setProducto(respuesta.data.productos);
      guardarImagen(respuesta.data.productos.imagen.split('/').pop())
      dispatch(obtenerCategorias());
    }

    if(!id){
      history.push('/user/listadoProductos')
    }

    dispatch(usuarioAutenticado())
    consultar();
    // eslint-disable-next-line
  }, [])

  const {categoria,nombre,contenido,_id} = producto;

  const [error, seterror] = useState(false);

  const cambiarFormulario = e => {
    setProducto({
      ...producto,
      [e.target.name]:e.target.value
    })
  }

  const guardarProductoForm = e => {
    e.preventDefault();
    // Validar formulario
    if(contenido.trim() === '' || nombre.trim() === '' || categoria === null ){
      seterror(true);
      return;
    }

    seterror(false);

    let formData = new FormData(e.target);
    formData.append("contenido",contenido);
    formData.append("nombreImagen",imagen);

    dispatch(editarPrducto(formData,_id));

    history.push('/user/listadoProductos')  ;
  
  }
  
    return (
      <Fragment>
        <Header/>
        <div className="principalContainer">
          <h1>Editar producto</h1>
          <FormularioNuevoProducto
            onSubmit={guardarProductoForm}
          >
            <input 
              type="file"
              name="imagen"
            />
            <input
              placeholder="Titulo"
              name="nombre"
              value={nombre}
              onChange={cambiarFormulario}
            />
            <select 
              name="categoria"
              value={categoria}
              onChange={cambiarFormulario}
            >
              <option value="">-- Selecione</option>
              { categorias.length !== 0 ? 
              
              categorias.map(categoria => (
                <option key={categoria._id} value={categoria._id}>{categoria.nombre}</option>
              ))
              : null}
            </select>
            <CKEditor
                editor={ ClassicEditor }
                data={contenido}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    if(Object.values(producto).length > 0){
                      setProducto({
                        ...producto,
                        contenido:data
                      })
                    }
                }}
            />
            {error ? <Error>Todos los campos son obligatorios</Error> : null}
            <div className="button-rigth">
                <button>Guardar</button>
            </div>
          </FormularioNuevoProducto>
        </div>
      </Fragment>
      );
}
 
export default EditarProducto;