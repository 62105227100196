import React,{useState,Fragment} from 'react';
import styled from '@emotion/styled'
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { loginUsuario } from '../actiones/actionsUsuarios';

const ContainerLogin = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 80vh;
`;

const LoginContent = styled.div`
    padding: 0 2rem;
    text-align: center;
    min-width: 30rem;
    border:1px solid #183153;
    border-radius: 0.2rem;

    h1{
        font-weight: 400;
        margin:1rem 0;
        font-size: 2.8rem;
    }
`;

const FormLogin = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;

    input{
        margin: 2rem 0;
        height: 3rem;
        font-size: 1.8rem;
    }

    button{
        margin-bottom: 2rem;
        height: 3rem;
        font-size: 1.8rem;
        background-color: #183153;
        color: #fff;
        border:none;
    }
`;

const Error = styled.p`
    background-color: #d60202;
    color: #fff;
    font-size: 1.6rem;
    padding: .5rem;
    border-radius: .2rem;
`;

const Login = (props) => {

    const dispatch = useDispatch();

    const errorLogin = useSelector(state => state.usuario.error);

    const [login, setlogin] = useState({
        email:'',
        password:''
    })

    const [error, setError] = useState('');

    const {email,password} = login;

    const colocarValores = e => {
        setlogin({
            ...login,
            [e.target.name]:e.target.value
        })
    }

    const submitLogin = e => {
        e.preventDefault();
        
        if(email.trim() === '' && password.trim() === ''){
            setError('Todos los campos son obligatorios');
            return;
        }

        setError('');

        dispatch(loginUsuario(login));

        setTimeout(()=> {if(!errorLogin) props.history.push('/user/listadoProductos')},1000)
    }

    return ( 
        <Fragment>
            <Header/>
            <ContainerLogin>
                <LoginContent>
                    <div>
                        <h1>Inicio de sesión</h1>
                    </div>
                    <FormLogin
                        onSubmit={submitLogin}
                    >
                        <input
                            type="text"
                            placeholder="Usuario"
                            name="email"
                            value={email}
                            onChange={colocarValores}
                        />
                        <input
                            type="password"
                            placeholder="Contraseña"
                            name="password"
                            value={password}
                            onChange={colocarValores}
                        />
                        {error.length !== 0 ? <Error>{error}</Error>:null}
                        {errorLogin ? <Error>Revisa tus credenciales</Error>:null}
                        <button
                            type="submit"
                        >
                            Acceder
                        </button>
                    </FormLogin>
                </LoginContent>
            </ContainerLogin>
        </Fragment>    
     );
}
 
export default Login;