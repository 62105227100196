import React,{useState,Fragment} from 'react';
import Header from './Header';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {enviarMail} from '../actiones/actionsContacto';

const FormularioContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    min-height: 60.5vh;
    margin-top: 5rem;

    @media only screen and (max-width:850px){
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
`;

const InformacionContacto = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-basis: calc(50% - 1rem);
    padding: 1rem;

    @media only screen and (max-width:850px){
        width: 100%;
    }
`;

const TituloForm = styled.div`
    h1{
        color: #283d50;
        font-weight: 500;
    }

    @media only screen and (max-width:850px){
        text-align: center;
    }

    
`;

const ItemInfoContact = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

    p:first-of-type{
    color: #283d50;
    font-size: 2.5rem;
    margin-right: 1rem;
    }

    p:last-of-type{
    color: #283d50;
    font-size: 1.6rem;
    line-height : 25px;
    }
`;

const FormContacto = styled.form`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    flex-basis: calc(60% - 1rem);
    padding: 5rem;

    @media only screen and (max-width:850px){
        width: 100%;
        padding: 1rem;
    }

    input{
        height: 3.5rem;
        margin-bottom: 2rem;
        color: #495057;
        font-size: 1.6rem;
        padding: .8rem;
        width: 100%;
        border: 1px solid #ccc;
        outline-color:#c8eeff;
    }

    textarea{
        margin-bottom: 2rem;
        color: #495057;
        resize: none;
        height: 10rem;
        font-size: 1.6rem;
        padding: .8rem;
        outline-color:#c8eeff;
        width: 100%;
        border: .5px solid #ccc;
    }

    button{
        width: 20rem;
        height: 4rem;
        font-size: 1.6rem;
        border: none;
        background-color: #111E3D;
        color: #fff;
        border-radius: .4rem;
        cursor: pointer;
        transition: ease all .4s;
    }

    button:hover{
        background: #0066D4;
    }

`;

const Error = styled.p`
    padding: 0.5rem;
    width: 100%;
    background: #fff;
    text-align: center;
    color: red;
    border:1px solid red;
`;

const Confirmacion = styled.p`
    padding: 0.5rem;
    width: 100%;
    background: #fff;
    text-align: center;
    color: #183153;
    border:1px solid #183153;
`;

const Contacto = () => {

    const dispatch = useDispatch();

    const loading = useSelector(state => state.contacto.loading);
    const errorSend = useSelector(state => state.contacto.error);
    const enviado = useSelector(state => state.contacto.enviado);

    const [error, seterror] = useState(false)

    const [contacto, setcontacto] = useState({
        nombre:'',
        email:'',
        asunto:'',
        mensaje:''
    })

    const {nombre,email,asunto,mensaje} = contacto;

    const guardarContacto = e => {
        setcontacto({
            ...contacto,
            [e.target.name] : e.target.value
        })
    }

    const submitContacto = e =>{
        e.preventDefault();

        if(nombre.trim() === '' || email.trim() === '' || asunto.trim() === '' || mensaje.trim() === ''){
            seterror(true);
            return
        }

        seterror(false);

        dispatch(enviarMail(contacto));


    }

    return ( 
        <Fragment>
            <Header/>
            <div className="principalContainer">
                <FormularioContainer>
                    <InformacionContacto>
                        <TituloForm>
                            <h1>Información de contacto</h1>
                        </TituloForm>
                        <div className="info-contacto-texto">
                            <ItemInfoContact>
                                <p><i className="fas fa-phone-alt"></i></p>
                                <p>(771) 4894152 / (771) 4894178 / (771)7944163</p>
                            </ItemInfoContact>

                            <ItemInfoContact>
                                <p><i className="far fa-envelope"></i></p>
                                <p>promotoria@dacida.com.mx</p>
                            </ItemInfoContact>

                            <ItemInfoContact>
                                <p><i className="fas fa-map-marker-alt"></i></p>
                                <p>Valle Banderas 314, Colonia Valle de San Javier, IV sección, Pachuca de Soto, Hidalgo, C.P. 42086</p>
                            </ItemInfoContact>
                        </div>
                    </InformacionContacto>
                    <FormContacto
                        onSubmit={submitContacto}
                    >
                        <input 
                            type="text" 
                            name="nombre" 
                            placeholder="Nombre completo"
                            onChange={guardarContacto} 
                            value={nombre}    
                        />
                        <input type="email" name="email" value={email} placeholder="Correo electrónico" onChange={guardarContacto}  />
                        <input type="text" name="asunto" value={asunto} placeholder="Asunto" onChange={guardarContacto}  />
                        <textarea name="mensaje" value={mensaje} placeholder="Mensaje" onChange={guardarContacto} ></textarea>
                        {error ? <Error>Todos los campos son obligatorios</Error> : null}
                        {!loading && errorSend ? <Error>No se ha podido enviar el email</Error> : null }
                        {!loading && !errorSend && enviado ? <Confirmacion>Contacto enviado correctamente</Confirmacion> : null }
                        <button type="submit" name="send">Solicitar información</button>
                    </FormContacto>
                </FormularioContainer>
            </div>
            <footer>
                <p>Todos los derechos reservados &copy;</p>
            </footer>
        </Fragment>
        
     );
}
 
export default Contacto;