import { combineReducers } from 'redux';
import productoReducer from './productosReducer';
import categoriasReducer from './categoriasReducer';
import usuariosReducer from './usuariosReducer';
import contactoreducer from './contactoreducer';


export default combineReducers({
    productos:productoReducer,
    categorias:categoriasReducer,
    usuario:usuariosReducer,
    contacto:contactoreducer
})