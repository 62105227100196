import {ENVIAR_CONTACTO,ENVIAR_CONTACTO_EXITO,ENVIAR_CONTACTO_ERROR} from '../types';
import clienteAxios from '../config/clienteAxios';

export function enviarMail(mail) {
    return async (dispatch) => {

        dispatch(prepararEnviarMail());

        try {
            await clienteAxios.post('/api/mail',mail);
            dispatch(evivarMailExito());
        } catch (error) {
            dispatch(evivarMailError());
        }
    }
}

const prepararEnviarMail = () => ({
    type:ENVIAR_CONTACTO
})

const evivarMailExito = () => ({
    type:ENVIAR_CONTACTO_EXITO
})

const evivarMailError = () => ({
    type:ENVIAR_CONTACTO_ERROR
})