import React,{Fragment,useState,useEffect} from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import {cerrarSesion} from '../actiones/actionsUsuarios';


const HeaderItem = styled.header`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    height: 11rem;
    background-color:#fff; 
    border-bottom:4px solid #111e3d ;

    img{
        width: 10rem;
    }

`;

const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    a{
        font-size: 1.8rem;
        text-decoration: none;
        font-family: 'Pt Sans',sans-serif;
        margin: 0 1rem;
        color: #000;
    }

    button{
        background: none;
        border: 0;
        cursor: pointer;
    }

@media only screen and (max-width:426px){
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    background: #e1e1e1;
    top:12rem;
    right: 2rem;
    width: 90%;

    a{
        padding: 1rem;
        border-bottom: 1px solid #000;
        width: 100%;
        text-align: center;
    }
    button{
        padding: 1rem;
        border-bottom: 1px solid #000;
        width: 100%;
        text-align: center;
    }

    .menu{
        
    }

}

`;

const BtnMenu = styled.button`
    display: none;
    background: #fff;
    border: 1px solid #183153;
    padding: .5rem 1rem;

    @media only screen and (max-width:426px){
        display: block;
    }

`;

const Header = () => {

    const autenticado = useSelector(state => state.usuario.autenticado);

    const dispatch = useDispatch();

    const [menu, setMenu] = useState(false);
    const [pantalla, setpantalla] = useState(0)

    document.addEventListener('click', function(event) {

        if(event.target.id !== 'btnNav' || event.target.id.length === 0 || event.target.id === ''){
            if(pantalla < 425){
                setMenu(false);
            }
        }
    });

    useEffect(()=>{
        const altura = window.innerWidth;
        if(altura < 426){
            setMenu(false)
            setpantalla(altura);
        }else{
            setMenu(true)
            setpantalla(altura);
        }

    },[])

    return ( 
        <HeaderItem>
            <img src={logo} alt="logo.png"/>
            {menu || pantalla > 425 ? 
            <Nav  id='nav'>
                {!autenticado ? (
                    <Fragment>
                        <Link to="/">Inicio</Link>
                        <Link to="/productos">Productos</Link>
                        <Link to="/contacto">Contacto</Link>
                        <Link to="/login">Login</Link>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Link to="/user/nuevo-producto">Nuevo Productos</Link>
                        <Link to="/user/listadoProductos">Productos</Link>
                        <Link to="/user/categorias">Categorias</Link>
                        <button
                            onClick={()=> dispatch(cerrarSesion())}
                        >Salir</button>
                    </Fragment>
                )}
            </Nav> : null}
            <BtnMenu 
                id='btnNav'
                onClick={()=>{
                    if(pantalla < 425){
                        setMenu(true)
                    }
                }}
            >Menu</BtnMenu>
        </HeaderItem>
     );
}
 

export default Header;