import React,{useEffect} from 'react';
import styled from '@emotion/styled'
import vegetables from '../img/vegetables2.jpg';
import logo from '../img/logo.png';
import mex from '../img/mexico.svg'
import {obtenerProductos} from '../actiones/actionsProductos';
import { useDispatch,useSelector } from 'react-redux';

// Components
import Header from './Header';
import Producto from './Producto';

const PrecetacionDacida = styled.div`
    height: 90vh;
    width: 100%;
    background-image: url(${vegetables});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
`;

const PrecentacionContenido = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    h1{
        color: #000;
        margin-top: 2rem;
        font-weight: 400;
        text-align: center;
    }

    a,p{
        color: #000;
        margin-top: 2rem;
        font-size: 2rem;
    }

    img{
        /* background-color:#fff; */
        padding: 1rem;
        width: 35rem;
        border-radius: .2rem;
    }
`;

const Quienes = styled.div`

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 8rem;

    @media only screen and (max-width:600px){
        flex-direction: column;

        p{
            padding: 2rem;
        }

    }

    h2{
            text-align: center;
    }

    p{
        text-align: justify;
        font-weight: 500;
        font-size: 2rem;
    }
`;

const Enlace = styled.a`
    background-color: #000;
    color: #fff;
    padding: 1rem 2rem;
    font-size: 1.8rem;
    cursor: pointer;
`;

const ImgBandera = styled.img`
    width:4rem!important;
    margin:0;
    padding: 0!important;
`;

const Inicio = () => {

    const dispatch = useDispatch();
    const productos = useSelector(state => state.productos.productos);

    useEffect(() => {
        dispatch(obtenerProductos());
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Header/>
            <PrecetacionDacida>
                <PrecentacionContenido>
                    <img src={logo} alt="Logo"/>
                    <h1>Importadora y exportadora</h1>
                    <a href='www.dacida.com.mx'>www.dacida.com.mx</a>
                    <p>2021</p>
                    <ImgBandera src={mex} alt="bandera" />
                </PrecentacionContenido>
                <div className="principalContainer">
                    <Quienes>
                        <div>
                            <h2>¿Quienes somos?</h2>
                            <p>
                            Somos una empresa que busca sinergia con la finalidad de desarrollar negocios de emprendedores, MICROPYMES y Grandes Empresas Mexicanas, buscando nuevas oportunidades de negocios con el resto del mundo utilizando y procurando la actualización de sus estructuras tradicionales a los nuevos esquemas de oportunidad económica que les permita crecimiento deseado
                            </p>
                        </div>
                        
                    </Quienes>
                    
                    <h2 className="mr-top-5 textCenter">Nuestros productos mas destacados</h2>

                    <div className="contenedorProducto">

                        {productos.map((item,index) => index <= 2 ? (
                            <Producto
                            key={item._id}
                            img={item.imagen}
                            titulo={item.nombre}
                            detalles={item.contenido}
                            />
                        ) : null)}
                    </div>

                    <div className="button-rigth">
                        <Enlace href="/productos">Ver todos</Enlace>
                    </div>

                </div>
                <footer>
                    <p>Todos los derechos reservados &copy;</p>
                </footer>
            </PrecetacionDacida>
        </div>
     );
}
 
export default Inicio;