// Crear productos
export const GUARDAR_PRODUCTO = 'GUARDAR_PRODUCTO';
export const GUARDAR_PRODUCTO_EXITO = 'GUARDAR_PRODUCTO_EXITO';
export const GUARDAR_PRODUCTO_ERROR = 'GUARDAR_PRODUCTO_ERROR';

export const OBTENER_PRODUCTOS = 'OBTENER_PRODUCTOS';
export const OBTENER_PRODUCTOS_EXITO = 'OBTENER_PRODUCTOS_EXITO';
export const OBTENER_PRODUCTOS_ERROR = 'OBTENER_PRODUCTOS_ERROR';

export const EDITAR_PRODUCTO = 'EDITAR_PRODUCTO';
export const EDITAR_PRODUCTO_EXITO = 'EDITAR_PRODUCTO_EXITO';
export const EDITAR_PRODUCTO_ERROR = 'EDITAR_PRODUCTO_ERROR';

export const ELIMINAR_PRODUCTO = 'ELIMINAR_PRODUCTO';
export const ELIMINAR_PRODUCTO_EXITO = 'ELIMINAR_PRODUCTO_EXITO';
export const ELIMINAR_PRODUCTO_ERROR = 'ELIMINAR_PRODUCTO_ERROR';


//  Crear categorias
export const CREAR_CATEGORIA = 'CREAR_CATEGORIA';
export const CREAR_CATEGORIA_EXITO = 'CREAR_CATEGORIA_EXITO';
export const CREAR_CATEGORIA_ERROR = 'CREAR_CATEGORIA_ERROR';

export const OBTENER_CATEGORIAS = 'OBTENER_CATEGORIAS';
export const OBTENER_CATEGORIAS_EXITO = 'OBTENER_CATEGORIAS_EXITO';
export const OBTENER_CATEGORIAS_ERROR = 'OBTENER_CATEGORIAS_ERROR';

export const EDITAR_CATEGORIA = 'EDITAR_CATEGORIA';
export const EDITAR_CATEGORIA_EXITO = 'EDITAR_CATEGORIA_EXITO';
export const EDITAR_CATEGORIA_ERROR = 'EDITAR_CATEGORIA_ERROR';

export const ELIMINAR_CATEGORIA = 'ELIMINAR_CATEGORIA';
export const ELIMINAR_CATEGORIA_EXITO = 'ELIMINAR_CATEGORIA_EXITO';
export const ELIMINAR_CATEGORIA_ERROR = 'ELIMINAR_CATEGORIA_ERROR';

// Usuarios
export const PRERPARA_USUARIOS = 'PRERPARA_USUARIOS';
export const NUEVO_USUARIO = 'NUEVO_USUARIO';
export const ERROR_CREAR_USUARIO = 'ERROR_CREAR_USUARIO';
export const INICIAR_SESION = 'INICIAR_SESION';
export const INICIAR_SESION_ERROR = 'INICIAR_SESION_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const CERRAR_SESION = 'CERRAR_SESION';


// Contacto 

export const ENVIAR_CONTACTO = 'ENVIAR_CONTACTO';
export const ENVIAR_CONTACTO_EXITO = 'ENVIAR_CONTACTO_EXITO';
export const ENVIAR_CONTACTO_ERROR =  'ENVIAR_CONTACTO_ERROR';
