import React,{Fragment,useEffect} from 'react';
import Header from './Header';
import Producto from './Producto';
import styled from '@emotion/styled';
import {obtenerCategorias} from '../actiones/actionsCategorias';
import {obtenerProductos} from '../actiones/actionsProductos';
import { useDispatch,useSelector } from 'react-redux';

const CategoriaNombre = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;

    p:first-of-type{
        width: 1rem;
        background-color: #111e3d;
        height: 3rem;
        margin-right: 2rem;
    }

    p:last-of-type{
        font-size: 2.5rem;
    }
`;

const Productos = () => {

    const dispatch = useDispatch();
    const categorias = useSelector(state => state.categorias.categorias);
    const productos = useSelector(state => state.productos.productos);


    useEffect(() => {
        dispatch(obtenerCategorias());
        dispatch(obtenerProductos());
        // eslint-disable-next-line
    }, [])

    return ( 
        <Fragment>
            <Header/>
            <div className="principalContainer">
                <div className="contenedorProducto">
                    {categorias.map(categoria => (
                        <Fragment key={categoria._id}>
                            <CategoriaNombre>
                        <p></p>
                        <p>{categoria.nombre}</p>
                        </CategoriaNombre>

                        <Fragment>
                            {productos.map(item => item.categoria === categoria._id ? (
                            <Producto
                            key={item._id}
                            img={item.imagen}
                            titulo={item.nombre}
                            detalles={item.contenido}
                            />
                    ) : null)}
                        </Fragment>
                    </Fragment>
                    ))}
                    
                    
                </div>
            </div>
            <footer>
                <p>Todos los derechos reservados &copy;</p>
            </footer>
        </Fragment>
     );
}
 
export default Productos;