import React,{useState,Fragment,useEffect} from 'react';
import Header from './Header';
import styled from '@emotion/styled';
import {crearCategoria,obtenerCategorias,editarCategoria,eliminarCategoria} from '../actiones/actionsCategorias';
import { useDispatch,useSelector } from 'react-redux';
import {usuarioAutenticado} from '../actiones/actionsUsuarios';

const FormCategoria = styled.form`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    input{
        flex-basis: ${ props => ( props.editar ? 'calc(88% - 1rem)' :  'calc(90% - 1rem)' )};
        height: 3rem;
        padding:.5rem;
    }

    button{
        flex-basis: calc(10% - 1rem) ;
        height: 3rem;
        padding:.5rem;
        color: #fff;
        background-color: #183153;
        border:none;   
        border-radius: .2rem;
        cursor: pointer;
    }

    .buttonEditar{
        background-color: #fff;
        flex-basis: calc(2% - 1rem);
        color: black;
    }

    @media only screen and (max-width: 650px){

        flex-direction: column;

        input{
            width: 100%;
            margin-bottom: 1rem;
        }

        button{
            width: 100%;
        }
    }
    
`;

const CategoriaItem = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
    margin-top: 2rem;

    div button {
        margin: 0 1rem;
        padding: 1rem 2rem;
        background-color: #fff;
        border: 1px solid #d60202;
        color: #d60202;
        cursor: pointer;
    }

    @media only screen and (max-width: 650px){
        flex-direction: column;
    }

`;

const Title = styled.h1`
    text-align: center; 
    font-weight: 400;
    margin-top: 2rem;
`;

const ButtonEditar = styled.button`
    border:1px solid #183153!important;
    padding: 1rem 2rem;
    cursor: pointer;
    color: #183153!important;
`;

const Categorias = () => {

    const dispatch = useDispatch();

    const categorias = useSelector(state => state.categorias.categorias);

    const [nombre, setNombre] = useState('');

    const [editar, setEditar] = useState(false);

    const [categoriaEditar, guardarCategoriaEditar] = useState({});

    useEffect(() => {
        dispatch(usuarioAutenticado())
        dispatch(obtenerCategorias());
        // eslint-disable-next-line
    }, [])

    const crearCategoriaSubmit = e => {
        e.preventDefault();

        if(nombre.trim() === '') return;
        const categoria = {nombre}
        if(!editar){
            dispatch(crearCategoria(categoria));
        }else{
            dispatch(editarCategoria(categoriaEditar))
            setEditar(false);
            setNombre('');
            guardarCategoriaEditar({});
        }
        setNombre('');
    }

    const colocarEditar = (e,categoria) => {
        setEditar(true);
        setNombre(categoria.nombre);
        guardarCategoriaEditar(categoria);
    }

    const eliminarCatBtn = categoria => {
        dispatch(eliminarCategoria(categoria));
    }

    return ( 
        <Fragment>
            <Header/>
            <div className="principalContainer">
                <FormCategoria
                    editar={editar}
                    onSubmit={crearCategoriaSubmit}
                >
                    <input 
                        placeholder="Nombre categoria"
                        name="nombre"
                        value={nombre}
                        onChange={e => {
                            setNombre(e.target.value)

                            if(editar){
                                guardarCategoriaEditar({
                                    ...categoriaEditar,
                                    [e.target.name]:e.target.value
                                })
                            }
                        }}
                    />
                    <button
                        type="submit"
                    >{editar ? 'Editar' : 'Guardar'}</button>
                    { editar ? 
                    <button 
                    className="buttonEditar"
                    onClick={()=>{
                        setEditar(false);
                        setNombre('');
                        guardarCategoriaEditar({});
                    }}
                    >
                        &#215;
                    </button> : null}
                </FormCategoria>
                <div>
                    <Title>Listado categorias</Title>
                    {categorias.map(categoria => (
                        <CategoriaItem
                            key={categoria._id}
                        >
                            <p>{categoria.nombre}</p>
                            <div>
                                <ButtonEditar 
                                    onClick={(e)=>colocarEditar(e,categoria)}
                                >Editar</ButtonEditar>
                                <button
                                    onClick={()=>eliminarCatBtn(categoria)}
                                >Eliminar</button>
                            </div>
                        </CategoriaItem>
                    ))}
                </div>
            </div>
        </Fragment>
     );
}
 
export default Categorias;