import React,{useEffect,Fragment} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {obtenerProductos,eliminarProducto} from '../actiones/actionsProductos';
import Header from './Header';
import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {usuarioAutenticado} from '../actiones/actionsUsuarios';


const CategoriaItem = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
    margin-top: 2rem;

    div button {
        margin: 0 1rem;
        padding: 1rem 2rem;
        background-color: #fff;
        border: 1px solid #d60202;
        color: #d60202;
        cursor: pointer;
    }

    @media only screen and (max-width: 650px){
        flex-direction: column;
    }

`;

const Title = styled.h1`
    text-align: center;
    font-weight: 400;
    margin-top: 2rem;
`;

const ButtonEditar = styled(Link)`
    border:1px solid #183153;
    padding: 1rem 2rem;
    cursor: pointer;
`;

const ListadoProductos = () => {

    const dispatch = useDispatch();

    const loading = useSelector(state => state.productos.loading)
    
    useEffect(() => {
        dispatch(usuarioAutenticado());
        if(!loading){
            dispatch(obtenerProductos());
        }
        console.log(productos);
        // eslint-disable-next-line
    }, [])

    const productos = useSelector(state => state.productos.productos);

    const eliminarProductoBtn = (producto) => {
        const nombreImagen = producto.imagen.split('/').pop();
        dispatch(eliminarProducto(producto,nombreImagen));
    }

    return ( 
        <Fragment>
        <Header/>
        <div className="principalContainer">
            <div>
                <Title>Listado productos</Title>
                {loading ? <p>Cargando...</p> : null}
                {  loading ? null : productos.map(producto => 
                    (<CategoriaItem
                    key={producto._id}
                    >
                            <p>{producto.nombre}</p>
                            <div>
                                <ButtonEditar to={`/user/editarProducto/${producto._id}`}>Editar</ButtonEditar>
                                <button
                                    onClick={() => eliminarProductoBtn(producto) }
                                >Eliminar</button>
                            </div>
                    </CategoriaItem>)
                )}
            </div>
        </div>
    </Fragment>
     );
}
 
export default ListadoProductos;