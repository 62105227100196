import {
    CREAR_CATEGORIA,
    CREAR_CATEGORIA_EXITO,
    CREAR_CATEGORIA_ERROR,
    OBTENER_CATEGORIAS,
    OBTENER_CATEGORIAS_EXITO,
    OBTENER_CATEGORIAS_ERROR,
    EDITAR_CATEGORIA,
    EDITAR_CATEGORIA_EXITO,
    EDITAR_CATEGORIA_ERROR,
    ELIMINAR_CATEGORIA,
    ELIMINAR_CATEGORIA_EXITO,
    ELIMINAR_CATEGORIA_ERROR
} from '../types';

const initialState = {
    categorias:[],
    error:null,
    loading:null,
    categoriasEliminar:null,
    categoriasEditar:null
};


export default function a(state = initialState, action) {

    switch(action.type){

        case ELIMINAR_CATEGORIA:
        case EDITAR_CATEGORIA:
        case OBTENER_CATEGORIAS:
        case CREAR_CATEGORIA:
            return {
                ...state,
                error:null,
                loading:true,
            }

        case CREAR_CATEGORIA_EXITO:
            return {
                ...state,
                categorias:[action.payload,...state.categorias],
                error:null,
                loading:null,
            }

        case OBTENER_CATEGORIAS_ERROR:
        case EDITAR_CATEGORIA_ERROR:
        case ELIMINAR_CATEGORIA_ERROR:
        case CREAR_CATEGORIA_ERROR:
            return {
                ...state,
                error:true,
                loading:null,
            }

        case OBTENER_CATEGORIAS_EXITO:
            return{
                ...state,
                categorias:action.payload,
                error:null,
                loading:null,
            }

        
        case EDITAR_CATEGORIA_EXITO:
            return {
                ...state,
                categorias:state.categorias.map(categoria => {
                    if(categoria._id === action.payload._id){
                        categoria.nombre = action.payload.nombre
                        return categoria;
                    }else{
                        return categoria;
                    }
                })
            }

        case ELIMINAR_CATEGORIA_EXITO:
            return {
                ...state,
                categorias:state.categorias.filter(cat => cat._id !== action.payload._id),
            }
        
        default:
            return state;
    }

}