import {ENVIAR_CONTACTO,ENVIAR_CONTACTO_EXITO,ENVIAR_CONTACTO_ERROR} from '../types';

const initialState = {
    loading:false,
    error:false,
    enviado:false
}

export default function a(state = initialState,action){
    switch (action.type) {
        case ENVIAR_CONTACTO:
            return {
                ...state,
                loading:true,
                error:false,
                enviado:false
            }

        case ENVIAR_CONTACTO_EXITO:
            return {
                ...state,
                loading:false,
                error:false,
                enviado:true
            }

        case ENVIAR_CONTACTO_ERROR:
            return {
                ...state,
                loading:false,
                error:false,
                enviado:false
            }
            
    
        default:
            return state;
    }
}