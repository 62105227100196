import React,{useState,Fragment,useEffect} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Header from './Header'; 
import styled from '@emotion/styled';
import {useDispatch,useSelector} from 'react-redux';
import {guardarProducto} from '../actiones/actionsProductos';
import {obtenerCategorias} from '../actiones/actionsCategorias';
import {usuarioAutenticado} from '../actiones/actionsUsuarios';

const FormularioNuevoProducto = styled.form`

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  select,input{
    margin:2rem 0;
    padding: .5rem;
  }

`;

const Error = styled.p`
  background-color: #d60202;
  color: #fff;
  width: 100%;
  padding: 1rem;
  text-align: center;
`;

const NuevoProducto = (props) => {

  const dispatch = useDispatch();

  const categorias = useSelector(state => state.categorias.categorias);
  const loading = useSelector(state => state.productos.loading)

  useEffect(() => {
    dispatch(usuarioAutenticado());
    dispatch(obtenerCategorias());
    // eslint-disable-next-line
  }, [])

  const [producto, setProducto] = useState({
    contenido:'',
    imagen:null,
    nombre:'',
    categoria:null
  })

  const [error, seterror] = useState(false)

  const {contenido,nombre,categoria,imagen} = producto;

  const cambiarFormulario = e => {
    setProducto({
      ...producto,
      [e.target.name]:e.target.value
    })
  }

  const guardarProductoForm = e => {
    e.preventDefault();
    // Validar formulario
    if(contenido.trim() === '' || nombre.trim() === '' || categoria === null || imagen === null){
      seterror(true);
      return;
    }

    seterror(false);

    let formData = new FormData(e.target);

    formData.append("contenido",contenido);

    dispatch(guardarProducto(formData));

    if(loading !== true ){
      props.history.push('/user/listadoProductos')

    }
    
  }
  
    return (
      <Fragment>
        <Header/>
        <div className="principalContainer">
          <h1>Crear nuevo producto</h1>
          <FormularioNuevoProducto
            onSubmit={guardarProductoForm}
            enctype="multipart/form-data"
          >
            <input 
              type="file"
              name="imagen"
              onChange={e =>   {
                setProducto({
                  ...producto,
                  imagen:e.target.files[0]
                })
              }
            }
            />
            <input
              placeholder="Titulo"
              name="nombre"
              onChange={cambiarFormulario}
            />
            <select
              name="categoria"
              onChange={cambiarFormulario}
            >
              <option value="">-- Selecione</option>
              { categorias.length !== 0 ? 
              
              categorias.map(categoria => (
                <option key={categoria._id} value={categoria._id}>{categoria.nombre}</option>
              ))
              : null}
            </select>
            <CKEditor
                      editor={ ClassicEditor }
                      data={contenido.contenido}
                      onChange={( event, editor ) => {
                          const data = editor.getData();
                          setProducto({
                            ...producto,
                            contenido:data
                          })
                      }}
                  />
            {error ? <Error>Todos los campos son obligatorios</Error> : null}
            <div className="button-rigth">
                <button>Guardar</button>
            </div>
          </FormularioNuevoProducto>
        </div>
      </Fragment>
      );
}
 
export default NuevoProducto;