import {
    GUARDAR_PRODUCTO,
    GUARDAR_PRODUCTO_EXITO,
    GUARDAR_PRODUCTO_ERROR,
    OBTENER_PRODUCTOS,
    OBTENER_PRODUCTOS_EXITO,
    OBTENER_PRODUCTOS_ERROR,
    EDITAR_PRODUCTO,
    EDITAR_PRODUCTO_EXITO,
    EDITAR_PRODUCTO_ERROR,
    ELIMINAR_PRODUCTO,
    ELIMINAR_PRODUCTO_EXITO,
    ELIMINAR_PRODUCTO_ERROR
} from '../types/index';


const initialState = {
    productos:[],
    error:null,
    loading:null,
    productoEliminar:null,
    productoEditar:null
}

// eslint-disable-next-line
export default function(state = initialState, action){
    switch(action.type) {
        case ELIMINAR_PRODUCTO:
        case EDITAR_PRODUCTO:
        case OBTENER_PRODUCTOS:
        case GUARDAR_PRODUCTO:
            return {
                ...state,
                loading:true,
                error:null
            }

        case GUARDAR_PRODUCTO_EXITO:
            return {
                ...state,
                loading:null,
                error:null,
                productos:[...state.productos,action.payload]
            }

        case ELIMINAR_PRODUCTO_ERROR:
        case EDITAR_PRODUCTO_ERROR:
        case OBTENER_PRODUCTOS_ERROR:
        case GUARDAR_PRODUCTO_ERROR:
            return{
                ...state,
                loading:null,
                error:true
            }

        case EDITAR_PRODUCTO_EXITO:
            return {
                ...state,
                loading:null,
                error:null,
            }

        case OBTENER_PRODUCTOS_EXITO:
            return {
                ...state,
                productos:action.payload,
                loading:null,
                error:null
            }

        case ELIMINAR_PRODUCTO_EXITO:
            return {
                ...state,
                productos:state.productos.filter(producto => producto._id !== action.payload._id)
            }

        default:
                return state; // Siempre retornas state

    }
}
