import React from 'react';
import { BrowserRouter as Router,Route,Switch } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Componentes
import Inicio from './components/Inicio';
import Productos from './components/Productos';
import Login from './components/Login';
import ListadoProductos from './components/ListadoProductos';
import Categorias from './components/Categorias';
import EditarProducto from './components/EditarProducto';
import Contacto from './components/Contacto';
import NuevoProducto from './components/NuevoProducto';
import RutaPrivada from './components/rutas/RutaPrivada';
import SesionIniciada from './components/rutas/SesionIniciada';


function App() {
  return (
    <Router>
      <Provider store={store}>
          <Switch>
            <Route exact path="/" component={Inicio} />
            <Route exact path="/productos" component={Productos} />
            <SesionIniciada exact path="/login" component={Login} />
            <Route exact path="/contacto" component={Contacto} />
            <RutaPrivada exact path="/user/categorias" component={Categorias} />
            <RutaPrivada exact path="/user/nuevo-producto" component={NuevoProducto} />
            <RutaPrivada exact path="/user/listadoProductos" component={ListadoProductos} />
            <RutaPrivada path="/user/editarProducto/:id" component={EditarProducto} />
          </Switch>
      </Provider>
    </Router>
  );
}

export default App;
