import {
    INICIAR_SESION,
    INICIAR_SESION_ERROR,
    OBTENER_USUARIO,
    CERRAR_SESION,
    PRERPARA_USUARIOS
} from '../types';

import clienteAxios from '../config/clienteAxios';
import tokenAuth from '../config/tokenauth';

// Login cuenta

export function loginUsuario (usuario) {
    return async (dispatch) => {
        dispatch({
            type:PRERPARA_USUARIOS
        })
        try {
            const respuesta = await clienteAxios.post('/api/auth',usuario);
            dispatch( guardarIniciarSesion(respuesta.data) );
        } catch (error) {
            const msg = error.response.data.msg;
            dispatch({
                type:INICIAR_SESION_ERROR,
                payload:msg
            })
        }        
    }
}

const guardarIniciarSesion = usuario => ({
    type:INICIAR_SESION,
    payload:usuario
}) 

// Retorna el usuario registrado

export function usuarioAutenticado (){
    return async (dispatch) => {

        dispatch({
            type:PRERPARA_USUARIOS
        })

        // TODO: Función para enviar el token por headers
        const token = localStorage.getItem('token');
        if(token){
            tokenAuth(token);

            try {
                const  respuesta = await clienteAxios.get('/api/auth');
                dispatch({
                    type:OBTENER_USUARIO,
                    payload:respuesta.data.usuario
                });

            } catch (error) {
                console.log(error); 
                dispatch({
                    type:CERRAR_SESION,
                });
            }

        }else{
            dispatch({
                type:CERRAR_SESION,
            });
        }
    }
}

export function cerrarSesion () {
    return (dispatch) => {
        localStorage.removeItem('token');
        dispatch({
            type:CERRAR_SESION
        })
    }
}