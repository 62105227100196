import {
    INICIAR_SESION,
    INICIAR_SESION_ERROR,
    OBTENER_USUARIO,
    CERRAR_SESION,
    PRERPARA_USUARIOS
} from '../types';

const initialState = {
    autenticado:null,
    token:null,
    usuario:null,
    error:null,
    loading:true
}

export default function a(state = initialState, action){

    switch (action.type) {

        case PRERPARA_USUARIOS:
        return{
            loading:true
        }

        case INICIAR_SESION:
            localStorage.setItem('token',action.payload.token)
            return{
                ...state,
                token:action.payload.token,
                autenticado:true,
                error: null,
                loading:false
            }
    
        case INICIAR_SESION_ERROR:
            localStorage.removeItem('token');
            return{
                ...state,
                error:true,
                autenticado:null,
                token:null,
                loading:false
            }

        case OBTENER_USUARIO:
            return {
                ...state,
                usuario:action.payload,
                autenticado:true,
                token:localStorage.getItem('token'),
                loading:false
            }

        case CERRAR_SESION:
            localStorage.removeItem('token');
            return {
                usuario:null,
                error: null,
                loading:false,
                autenticado:null,
                token:null,
            }

        default:
            return state;
    }
}