import {
    GUARDAR_PRODUCTO,
    GUARDAR_PRODUCTO_EXITO,
    GUARDAR_PRODUCTO_ERROR,
    OBTENER_PRODUCTOS,
    OBTENER_PRODUCTOS_EXITO,
    OBTENER_PRODUCTOS_ERROR,
    EDITAR_PRODUCTO_EXITO,
    EDITAR_PRODUCTO_ERROR,
    ELIMINAR_PRODUCTO,
    ELIMINAR_PRODUCTO_EXITO,
    ELIMINAR_PRODUCTO_ERROR
} from '../types/index';

import clienteAxios from '../config/clienteAxios';

export function guardarProducto (producto) {

    return async (dispatch) => {
        dispatch(preparaGuardarProducto());
        try {
            const respuesta = await clienteAxios.post('/api/productos',producto);
            dispatch( guardarProductoExito(respuesta.data.producto) );
        } catch (error) {
            console.log(error.response);
            dispatch( guardaProductoError() );
        }
    }
}

const preparaGuardarProducto = () => ({
    type:GUARDAR_PRODUCTO
})

const guardarProductoExito = producto => ({
    type:GUARDAR_PRODUCTO_EXITO,
    payload:producto
})

const guardaProductoError = () => ({
    type:GUARDAR_PRODUCTO_ERROR 
})

export function obtenerProductos () {
    return async (dispatch) =>{

        dispatch(obtenerProductosPreparar());

        try {
            const respuesta = await clienteAxios.get('/api/productos');
            dispatch(obtenerProductosExito(respuesta.data.productos));
        } catch (error) {
            dispatch(obtenerProductosError());
        }
    }
}

const obtenerProductosPreparar = () => ({
    type:OBTENER_PRODUCTOS
})

const obtenerProductosExito = (productos) => ({
    type:OBTENER_PRODUCTOS_EXITO,
    payload:productos
})

const obtenerProductosError = () => ({
   type:OBTENER_PRODUCTOS_ERROR, 
})

export function editarPrducto (producto,id) {
    return async (dispatch) => {
        try {
            await clienteAxios.put(`/api/productos/${id}`,producto);
            dispatch(editarProductoExito(producto));
        } catch (error) {
            dispatch(editarProductoError());
        }
    }
}

const editarProductoExito = (producto) => ({
    type:EDITAR_PRODUCTO_EXITO,
    payload:producto

})

const editarProductoError = () => ({
    type:EDITAR_PRODUCTO_ERROR
})

export function eliminarProducto (producto,nombreImagen){
    return async (dispatch) => {
        const id = producto._id;
        
        await clienteAxios.delete(`/api/productos/${id}/${nombreImagen}`);
        dispatch(prepraraEliminarProducto);
        try {
            dispatch(eliminarProductoExito(producto))
        } catch (error) {
                dispatch(eliminarProductoError())
        }
    }
} 

const prepraraEliminarProducto = () => ({
    type:ELIMINAR_PRODUCTO
})

const eliminarProductoExito = (producto) => ({
    type:ELIMINAR_PRODUCTO_EXITO,
    payload:producto
})

const eliminarProductoError = () => ({
    type:ELIMINAR_PRODUCTO_ERROR,
})